var FieldCheckbox;

(function($){

	FieldCheckbox = {

		$items : null,

		init : function(){

			this.$items = $( 'label.checkbox' );
			if( !this.$items.length ) return true;

			this.$items.each(function(){
				FieldCheckbox.configure( this );
			});

		},

		configure : function( field ){

			field.$ = field.$ || $( field );

			var data = field.$.data( 'FieldCheckbox' );
			if( data !== undefined ) return data;

			data = {
				self : field, 
				$ : field.$,
				$field : field.$.find( 'input' ),
				$label : field.$.find( 'label' ),
				timeFocus : false
			};

			// Check is filled
			FieldCheckbox.checkClass( data, true );

			// Bind focus
			data.$field
				.on( 'focus', function(){
					data.$.addClass( 'focus' );
					// Check is filled
					FieldCheckbox.checkClass( data );
				})
				.on( 'blur focusout', function(){
					data.$.removeClass( 'focus' );
					// Check is filled
					FieldCheckbox.checkClass( data );
				})
				.on( 'change keypress', function(){
					// Check is filled
					FieldCheckbox.checkClass( data );
				})
			;

			// Bind label click
			data.$label.on( 'click', function(){
				
				// Clear timeout
				if( data.timeFocus ) clearTimeout( data.timeFocus );
				data.timeFocus = setTimeout(function(){
					// Know if field has focus
					const focused = ( data.field === document.activeElement );
					if( focused ){
						data.$.addClass( 'focus' );
					}
				}, 1 );
			});

			// Disabled
			if( data.$field.prop( 'disabled' ) ){
				data.$.addClass( 'disabled' );
			}
			else {
				data.$.removeClass( 'disabled' );
			}

		},

		// Check is filled
		checkClass : function( data, initing = false ){

			// Set indeterminate intial
			if( initing === true && data.$.hasClass( 'indeterminate' ) ){
				data.$field.prop( 'indeterminate', true );
			}


			console.info( 'initing' );
			console.log( initing );
			

			// Remove all classes
			data.$.removeClass( 'filled checked indeterminate disabled' );

			// Remove indeterminate if checked
			if( data.$field.prop( 'checked' ) ){
				data.$field.prop( 'indeterminate', false );
			}

			// Remove checked if indeterminate
			if( data.$field.prop( 'indeterminate' ) ){
				data.$field.prop( 'checked', false );
			}

			// Check is filled
			var filled = data.$field.prop( 'checked' ) || data.$field.prop( 'indeterminate' );
			if( filled ){
				data.$.addClass( 'filled' );
				data.$.addClass( data.$field.prop( 'checked' ) ? 'checked' : 'indeterminate' );
			}

			// Disabled
			if( data.$field.prop( 'disabled' ) ){
				data.$.addClass( 'disabled' );
			}
			else {
				data.$.removeClass( 'disabled' );
			}

		}

	};
	$(function(){
		FieldCheckbox.init();
	});

})(jQuery);

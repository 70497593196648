var Alertas;

(function($){

	Alertas = {

		$items : null,

		init : function(){

			this.$items = $( 'div.alerta' );
			if( !this.$items.length ) return true;

			this.$items.each(function(){
				Alertas.configure( this );
			});

		},

		configure : function( item ){

			item.$ = item.$ || $( item );

			var data = item.$.data( 'Alertas' );
			if( data !== undefined ) return data;

			data = {
				self : item, 
				$ : item.$,
				$button : item.$.find( 'button' )
			};

			if( data.$button.length ){
				data.$button.on( 'click', function(e){
					e.preventDefault();
					Alertas.close( data );
				});
			}

		},

		close : function( data ){
			data.$
				.addClass( 'closing' )
				.slideUp( 600, function(){
					data.$.remove();
				});
			;
		}

	};
	$(function(){
		Alertas.init();
	});

})(jQuery);

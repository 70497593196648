var Modal;
(function($){

    Modal = {

        data : {
            $nav : false
        },
        timeResize : false,
        timeClose : false,
        
        configure : function( options ){

            var $body = $( 'body' );

            // Remove older
            this.data.$ = $body.children( '#modal' );
            if( this.data.$.length ) this.data.$.remove();

            // Create elements    
            this.data.$ = $( '<div id="modal" class="bbpvd-modal"></div>' ).appendTo( $body );
            this.data.$inner = $( '<div class="bbpvd-modal-inner"></div>' ).appendTo( this.data.$ );

            // Fit screen
            this.data.fitScreen = options.fitScreen !== undefined && options.fitScreen === true;

            // Content
            this.data.$content = $( '<div class="content"></div>' ).appendTo( this.data.$inner );
            this.data.$scroller = $( '<div class="scroller"><div class="center-content">' + options.message + '</div></div>' ).appendTo( this.data.$content );

            // Ícone
            if( options.icon !== false && options.icon !== undefined ){
                this.data.$icon = $( '<img src="assets/icons/' + options.icon + '.svg" class="icon size--l" alt=" ">' );
                this.data.$icon.prependTo( this.data.$scroller );
            }
            else {
                this.data.$icon = false;
            }

            // Title
            if( options.title !== false && options.title !== undefined ){
            
                this.data.$title = $( '<em class="modal-title">'+ options.title +'</em>' );

                if( !this.data.fitScreen && options.title_location !== undefined && options.title_location === 'inside' ){
                    this.data.$title.prependTo( this.data.$scroller );
                }
                else{
                    this.data.$title.prependTo( this.data.$inner );
                }
            
            }
            else {
                this.data.$title = false;
            }

            // Close
            if( options.closeButton === undefined || options.closeButton !== false ){
                this.$close = $( '<button class="close-button native" data-action="close-modal">Fechar</button>' ).appendTo( this.data.$inner );
            }
            
            // Classes
            if( options.classname !== undefined ){
                this.data.$.addClass( options.classname );
            }
            if( this.data.fitScreen ){
                this.data.$.addClass( 'fit-screen' );
            }

            // Others close buttons
            $body.on( 'click', '[data-action="close-modal"]', function(e){
                this.$ = this.$ || $( this );
                if( this.$.parents( '#modal' ).length ){
                    Modal.close();
                }
            });

            // Navigation
            if( options.buttons !== undefined ){

                // Navigation
                this.data.$nav = $( '<nav />' );
                if( !this.data.fitScreen && options.buttons_location !== undefined && options.buttons_location === 'inside' ){
                    this.data.$nav.appendTo( this.data.$scroller );
                }
                else{
                    this.data.$nav.appendTo( this.data.$inner );
                }

                // Buttons
                $.each( options.buttons, function(i){

                    var button = this;

                    // Attributes
                    var atrributes = '';
                    if( button.attributes !== undefined ){
                        $.each( button.attributes, function( key ){
                            atrributes += key + '="' + this + '"';
                        });
                    }

                    // Create object
                    if( button.url !== undefined ){
                        button.$ = $( '<a href="'+ button.url +'" '+ atrributes + '>' + button.label +'</a>' );
                    }
                    else {
                        button.$ = $( '<button type="button"'+ atrributes + '>' + button.label +'</button>' );
                    }
                    // Insert object
                    button.$.appendTo( Modal.data.$nav );
                    
                    // CSS class
                    if( button.classname !== undefined ){
                        button.$.attr( 'class', button.classname );
                    }
                    // Style
                    if( button.style !== undefined ){
                        button.$.css( button.style );
                    }
                    // Bind click
                    button.$.off( '.Modal' ).on( 'click.Modal', function(e){
                        // Close
                        if( button.close === undefined || button.close === true ){
                             Modal.close();
                        }
                        // Callback
                        if( button.callback ){
                            try {
                                button.callback();
                            }
                            catch(e){
                                console.log( 'Não foi possível acessar o método ' + button.callback );
                            }

                        }
                    });
                    
                });

            }

            // Keep options
            this.options = options;
        
        },
        
        open : function( content, title ){

            var options;
            if( typeof( content ) === 'object' ){
                options = content;
            }
            else {
                options = {
                    message : content, 
                    title : ( title === undefined ? false : title )
                };
            }

            // Time to close
            if( Modal.timeClose ) clearTimeout( Modal.timeClose );

            // Configuring
            Modal.configure( options );

            // Close triggers
            this.data.$content.on( 'click', '[data-action="close"]', function(e){
                e.preventDefault();
                Modal.close();
            });

            // Showing
            Common.lockScroll();

            // Stop resize bind
            $(window).off( 'resize.Modal' );

            // If fit screen
            if( this.data.fitScreen ){

                // Custom scroll
                Modal.customScroll( this.data );

                var _data = this.data;

                $(window).on( 'resize.Modal', function(){

                    if( Modal.timeResize ) clearTimeout( Modal.timeResize );
                    Modal.timeResize = setTimeout(function(){
                        Modal.checkSize( _data );
                    }, 10 );
                
                }).resize();

            }

            // Add class
            this.data.$.addClass( 'opened' );

        },

        customScroll : function( data ){
            // Custom scroller
            try {
                data.$scroller.mCustomScrollbar({
                    axis: 'Y',
                    scrollbarPosition: 'outside',
                    autoHideScrollbar: true,
                    autoExpandScrollbar: true,
                    alwaysShowScrollbar: false
                });
            }
            catch(err){ 
                console.info( 'O plugin mCustomScrollbar não foi encontrado.' ); 
            }
        },

        checkSize : function( data ){

            // Leave to calculate size
            data.$content.css( 'max-height', '' );
            data.$inner.css( 'height', '100%' );

            // Leave custom scroller to calculate
            var $customScroller = $( '.mCustomScrollBox', data.$ );
            if( $customScroller.length ) $customScroller.css( 'max-height', '100%' );

            // Discount title
            if( data.$title || data.$nav ){

                // Total space height
                var spaceHeight = data.$inner.innerHeight() - parseInt( data.$inner.css( 'padding-top' ) ) - parseInt( data.$inner.css( 'padding-bottom' ) );
                
                if( data.$title ){
                    spaceHeight -= data.$title.outerHeight();
                }
                if( data.$nav ){
                    spaceHeight -= data.$nav.outerHeight();
                } 

                data.$content.css( 'max-height', spaceHeight + 'px' );

            }
            
            data.$inner.attr( 'style', '' );

        },
        
        // Closing
        close : function(){

            $(window).off( 'resize.Modal' );

            // Is not opened
            if( this.data.$ === undefined ) return;

            // Remove opened class
            this.data.$.removeClass( 'opened' );
            
            // Lock body scroll
            Common.unlockScroll();
           
            // Showing
            this.data.$.removeClass( 'opened' );

            // Removing
            if( this.timeClose ) clearTimeout( this.timeClose );
            this.timeClose = setTimeout(function(){
                Modal.data.$.remove();
            }, 1200 );

            // Callback
            if( this.options.onClose !== undefined ){
                this.options.onClose();
            }
        
        }

    };
    
})(jQuery);

var Common;

(function($){

	Common = {

		gadget : false,

		fixedScroll : 0,

		init : function(){
			this.checkSize();
		},

	    // Gadget
	    checkSize : function(){

	    	// Viewport width
	    	const viewportWidth = Common.getVPWidth();

	    	// Check size
	    	if( viewportWidth >= 769 ){
	    		if( Common.gadget != 'desktop' ){
		    		Common.gadget = 'desktop';
	    		}
	    	}
	    	else {
	    		if( Common.gadget !== 'mobile' ){
	    			Common.gadget = 'mobile';
	    		}
	    	}

	    	return Common.gadget;
	    	
	    },
	    
	    getGadget : function(){
	    	return this.checkSize();
	    },
		
		deviceType : function(){
		    const ua = navigator.userAgent;
		    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		        return "tablet";
		    }
		    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
		        return "mobile";
		    }
		    return "desktop";
		},

	    getPageTop : function(){
	    	return ( window.pageYOffset || document.documentElement.scrollTop ) - ( document.documentElement.clientTop || 0 );
	    },

		getPageHeight : function(){
		    var doc = document;
		    return  Math.max(
		        doc.body.scrollHeight, doc.documentElement.scrollHeight,
		        doc.body.offsetHeight, doc.documentElement.offsetHeight,
		        doc.body.clientHeight, doc.documentElement.clientHeight
		    );
		},

		getVPWidth : function(){
			return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		},

		getVPHeight : function(){
			return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		},

		checkIfBottom : function(){

			var 
				pageHeight = this.getPageHeight(),
				pageTop = this.getPageTop(),
				viweportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			;
			return pageTop >= ( pageTop + viweportHeight - 100 );

		},

		lockScroll : function(){

			this.fixedScroll = this.getPageTop();

			$(window).bind( 'scroll.locking', function(e){
				e.preventDefault();
				$(window).scrollTop(Common.fixedScroll);
			});
			
		},

		unlockScroll : function(){
			$(window).unbind( 'scroll.locking' );
		},
		
	    scrollTo : function( selector, spaceTop ){

	        var $target = typeof( selector ) === 'object' ? selector : $( selector );            
	        if( !$target.length ) return;

			var newY;
	        if( $target.attr( 'id' ) === 'inicio' ){
	        	newY = 0;
	        }
	        else {
		        newY = $target.offset().top;
	        }

	        var actualY = _window.scrollTop();
	        var distance = actualY - newY;

	        if( distance < 0 ) distance *= -1;
	        
	        var time = ( 600 - ( distance * 0.1 ) );
	        if( time < 600 ) time = 600;
	        
	        var offset = $target.data( 'offset' );
	        if( offset ){

	            try {

	                var 
	                    _json = JSON.parse( offset.split( "'" ).join( '"' ) )
	                    _offset = false,
	                    _vWidth = Navigation.getVPWidth()
	                ;

	                $.each( _json.breaks, function( _break ){
	                    if( _vWidth <= _break ){
	                        _offset = parseInt( this );
	                        return false;
	                    }
	                });

	                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

	            }
	            catch( e ){

	                var _offset = parseInt( offset );
	                offset = _offset > 0 ? _offset : 0;
	            
	            }

	        }
	        else {

	            offset = 0;
	        
	        }
	      	if( spaceTop !== undefined ){
	      		offset += spaceTop;
	      	}

	        if( this.$header ){
	        	offset += this.$header.outerHeight();
	        }

	        $( 'html, body' ).animate({
	            scrollTop: ( newY - ( offset ) )
	        }, 450, 'easeOutQuad' );      

	    },

		removeAccents : function( strAccents ){
		    
		    var strAccents = strAccents.split('');
		    var strAccentsOut = [];
		    var strAccentsLen = strAccents.length;
		    var accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
		    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
		    for( var y = 0; y < strAccentsLen; y++ ){
		        if( accents.indexOf( strAccents[ y ] ) != -1 ){
		            strAccentsOut[ y ] = accentsOut.substr( accents.indexOf( strAccents[ y ] ), 1 );
		        } else
		            strAccentsOut[ y ] = strAccents[ y ];
		    }
		    strAccentsOut = strAccentsOut.join( '' );

		    return strAccentsOut;
		
		}

	}

})(jQuery);

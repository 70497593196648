var FieldInput;

(function($){

	FieldInput = {

		$items : null,

		init : function(){

			this.$items = $( 'div.field-input' );
			if( !this.$items.length ) return true;

			this.$items.each(function(){
				FieldInput.configure( this );
			});

		},

		configure : function( field ){

			field.$ = field.$ || $( field );

			var data = field.$.data( 'FieldInput' );
			if( data !== undefined ) return data;

			data = {
				self : field, 
				$ : field.$,
				$field : field.$.find( 'input' ),
				$label : field.$.find( 'label' ),
				$button : field.$.find( 'button[data-action="show-value"]' ),
				$suffix : field.$.find( '.suffix' ),
				$strength : field.$.find( '.password-strenght' ),
				prefix : field.$.data( 'prefix' ),
				timeFocus : false
			};
			data.field = data.$field.get(0);

			// Prefix
			if( data.prefix !== undefined && data.prefix && data.prefix !== '' ){
				// Add prefix class
				data.$.addClass( 'with-prefix' );
				// Insert prefix
				data.$prefix = $( '<em class="prefix">' + data.prefix + '</em>' ).prependTo( data.$.children( 'span' ) );
				// Bind prefix click
				data.$prefix.on( 'click', function(){
					data.$field.focus();
				});
			}

			// Suffix
			if( data.$suffix.length ){
				data.$.addClass( 'with-suffix' );
			}

			// Check is filled
			FieldInput.checkFillClass( data );

			// Bind focus
			data.$field
				.on( 'focus', function(){
					data.$.addClass( 'focus' );
					// Check is filled
					FieldInput.checkFillClass( data );
				})
				.on( 'blur focusout', function(){
					data.$.removeClass( 'focus' );
					// Check is filled
					FieldInput.checkFillClass( data );
				})
				.on( 'change keypress', function(){
					// Check is filled
					FieldInput.checkFillClass( data );
				})
			;

			if( data.$strength.length ){

				// Criteriios
				data.strength = {
					$minlength : data.$strength.children( '[data-rule="minlength"]' ),
					$lowercase : data.$strength.children( '[data-rule="lowercase"]' ),
					$uppercase : data.$strength.children( '[data-rule="uppercase"]' ),
					$number : data.$strength.children( '[data-rule="number"]' ),
					$special : data.$strength.children( '[data-rule="special"]' )
				};

				// Bind value changes
				data.$field.on( 'keyup', function(){
					FieldInput.checkStrenght( data );
				});

				// Check on load
				FieldInput.checkStrenght( data );

			}

			// Bind label click
			data.$label.on( 'click', function(){
				
				// Clear timeout
				if( data.timeFocus ) clearTimeout( data.timeFocus );
				data.timeFocus = setTimeout(function(){
					// Know if field has focus
					const focused = ( data.field === document.activeElement );
					if( focused ){
						data.$.addClass( 'focus' );
					}
				}, 1 );
			});

			// Disabled
			if( data.$field.prop( 'disabled' ) ){
				data.$.addClass( 'disabled' );
			}
			else {
				data.$.removeClass( 'disabled' );
			}

			// Toggle button
			if( data.$button.length && data.$field.prop( 'disabled' ) !== true ){

				data.$button.on( 'click', function(e){
				
					e.preventDefault();
				
					var new_type = data.$field.attr( 'type' ) === 'text' ? 'password' : 'text';
					data.$field.attr( 'type', new_type );

					if( new_type === 'text' ){
						data.$button.addClass( 'active' );
					} 
					else{
						data.$button.removeClass( 'active' );
					}
				
				});

			}

		},

		// Check is filled
		checkFillClass : function( data ){
			// Filled class
			if( data.$field.val() !== '' ){
				data.$.addClass( 'filled' );
			}
			else {
				data.$.removeClass( 'filled' );
			}
		},

		strengthTest : function( field ){
			return (
				// Test size
				( !data.strength.$minlength.length || field.value.length >= 6 )
				&& 
				// Teste lowercase
				( !data.strength.$lowercase.length || field.value.match(/[a-z]+/) )
				&& 
				// Teste Uppercase
				( !data.strength.$uppercase.length || field.value.match(/[A-Z]+/) )
				&&
				// Test number
				( !data.strength.$number.length || field.value.match(/[0-9]+/) )
				&&
				// Special character
				( !data.strength.$special.length || field.value.match(/[$@#&!]+/) )
			);
		},

		checkStrenght : function( data ){

			var password = data.$field.val();
			var strength = 0;
			var criters = 0;

			// Test size
			if( data.strength.$minlength.length ){

				criters += 1;

				if( password.length >= 6 ){
					strength += 1;
					data.strength.$minlength.addClass( 'checked' );
				}
				else {
					data.strength.$minlength.removeClass( 'checked' );
				}

			}

			// Teste lowercase
			if( data.strength.$lowercase.length ){

				criters += 1;
				
				if( password.match(/[a-z]+/) ){
					strength += 1;
					data.strength.$lowercase.addClass( 'checked' );
				}
				else {
					data.strength.$lowercase.removeClass( 'checked' );
				}

			}

			// Teste Uppercase
			if( data.strength.$uppercase.length ){

				criters += 1;

				if( password.match(/[A-Z]+/) ){
					strength += 1;
					data.strength.$uppercase.addClass( 'checked' );
				}
				else {
					data.strength.$uppercase.removeClass( 'checked' );
				}

			}

			// Test number
			if( data.strength.$number.length ){

				criters += 1;

				if( password.match(/[0-9]+/) ){
					strength += 1;
					data.strength.$number.addClass( 'checked' );
				}
				else {
					data.strength.$number.removeClass( 'checked' );
				}

			}

			// Special character
			if( data.strength.$special.length ){

				criters += 1;

				if( password.match(/[$@#&!]+/) ){
					strength += 1;
					data.strength.$special.addClass( 'checked' );
				}
				else {
					data.strength.$special.removeClass( 'checked' );
				}

			}

			// Keep result and Trigger
			if( strength === criters ){
				data.strengthStatus = 'success';
				data.$field.trigger( 'strength', { status : 'success' } );
			}
			else {
				data.strengthStatus = 'fail';
				data.$field.trigger( 'strength', { status : 'fail' } );
			}
		
		}

	};

	$(function(){
		FieldInput.init();
	});

})(jQuery);

var FieldSelect;

(function($){

	FieldSelect = {

		$items : null,

		init : function(){

			this.$items = $( 'div.field-select' );
			if( !this.$items.length ) return true;

			this.$items.each(function(){
				FieldSelect.configure( this );
			});

			// Bind window events
			document.addEventListener( 'click', function( e ){

				// Opened select
				$openeds = $( 'div.field-select.opened' );
				if( !$openeds.length ) return true;

				// Elements
				e.target.$ = e.target.$ || $( e.target );
				if( e.target.$.hasClass( 'field-select' ) ){
					e.target.$select = e.target.$;
				}
				else {
					e.target.$select = e.target.$select || e.target.$.parents( 'div.field-select' );
				}

				// Close items
				$openeds.each(function(){

					this.$ = this.$ || $( this );

					if( !e.target.$select.length || !e.target.$select.is( this.$ ) ){
						var data = this.$.data( 'FieldSelect' );
						FieldSelect.close( data );
					}
				
				});

			});

		},

		// Configure each field
		configure : function( field ){

			field.$ = field.$ || $( field );

			var data = field.$.data( 'FieldSelect' );
			if( data !== undefined ) return data;

			data = {
				self : field, 
				$ : field.$,
				$area : field.$.children( 'span' ),
				$field : field.$.find( 'select' ),
				$label : field.$.find( 'label' ),
				$value : field.$.find( 'em' ),
				prefix : field.$.data( 'prefix' ),
				searching : false,
				searchTime : false,
				timeFocus : false,
				lockHover : false
			};

			// Area
			data.$area = $( '<span></span>' ).prependTo( field.$ );
						
			// Value
			data.$value = $( '<em></em>' ).prependTo( data.$area );

			// Put label in area
			data.$label.appendTo( data.$area );

			// Field self
			data.field = data.$field.get(0);

			// Select options
			data.$options = $( 'option', data.$field );
			
			// Search and actions
			data.$search = $( '<input type="text" value="">' ).appendTo( data.$area );
			data.search = data.$search.get( 0 );

			// Create dropdown
			data.$drop = $( '<div class="droplist"></div>' ).appendTo( data.$area );
			data.$dropList = $( '<ul tabindex="-1"></ul>' ).appendTo( data.$drop );
			data.$options.each(function(){
				$( '<li rel="'+ this.value +'" tabindex="0">'+ this.innerHTML +'</li>' ).appendTo( data.$dropList );
			});
			data.$dropItems = data.$dropList.children( 'li' );

			// Keep data
			field.$.data( 'FieldSelect', data );

			// Prefix
			if( data.prefix !== undefined && data.prefix && data.prefix !== '' ){

				// Add prefix class
				data.$.addClass( 'with-prefix' );
			
				// Insert prefix
				data.$prefix = $( '<em class="prefix">' + data.prefix + '</em>' ).prependTo( data.$area );
			
				// Bind prefix click
				data.$prefix.on( 'click', function(){
					data.$search.focus();
				});
			
			}

			// Bind events
			FieldSelect.bind( data );

			// Actualize
			FieldSelect.actualize( data );

		},

		getVisibleOptions : function( data ){

			var $options = data.$field.children( 'option' );
			if( data.$field.val() === '' ){
				$options = $options.filter( ':not([value=""])' );
			}

			return $options;

		},

		// Bind events
		bind : function( data ){

			// Body mousemove
			document.addEventListener( 'mousemove', function(){
				data.lockHover = false;
			});

			// Bind document scroll
			document.addEventListener( 'scroll', function(){
				FieldSelect.checkPositions();
			});

			// Bind focus
			data.$search
				.on( 'focus', function(){
				
					data.$.addClass( 'focus' );
				
					// Actualize
					FieldSelect.actualize( data );
				
				})
				.on( 'blur focusout', function(){

					data.$.removeClass( 'focus' );
					
					// Actualize
					FieldSelect.actualize( data );
				
				})
				.on( 'keyup', function( e ){

					// Check controls
					FieldSelect.bindControl( e, data );

					// Actualize
					FieldSelect.actualize( data );
				
				})
			;
			
			// Bind focus
			data.$field
				.on( 'change', function( e ){
					// Actualize
					FieldSelect.actualize( data );
				})
			;

			// Bind label click
			data.$label.on( 'click', function(){
				
				// Clear timeout
				if( data.timeFocus ) clearTimeout( data.timeFocus );
				data.timeFocus = setTimeout(function(){
					// Know if field has focus
					const focused = ( data.search === document.activeElement );
					if( focused ){
						data.$.addClass( 'focus' );
					}
				}, 1 );
			});

			// Set focus
			data.$value.on( 'click', function(){
				FieldSelect.toggle( data );
			});

			// Bind drop list scroll
			data.$dropList.scroll(function(){
				data.scrolling = true;
				if( data.timeScroll ) clearTimeout( data.timeScroll );

				data.timeScroll = setTimeout(function(){
					data.scrolling = false;
				}, 1000 );

			});

			// Bind options
			data.$dropItems.hover( 
				function(){
			
					this.$ = this.$ || $( this );
			
					// Pre select value
					if( !data.lockHover ){
						FieldSelect.preSelectValue( data, this.$.attr( 'rel' ) );
					}
			
				},
				function(){

					this.$ = this.$ || $( this );
					
					if( !data.lockHover ){
						this.$.removeClass( 'hover' );
					}
				
				}
			);
			data.$dropItems.on( 'click', function(){

				this.$ = this.$ || $( this );

				FieldSelect.setValue( data, this.$.attr( 'rel' ) );
				FieldSelect.close( data );
    
			});

		},

		// Pre select value (hover, keys navigation);
		preSelectValue : function( data, value, checkVisible ){
		
			// Item to check
			var $item = data.$dropItems.filter( '[rel="'+ value +'"]' );

			// Pré select item
			data.$dropItems.removeClass( 'hover' );
			$item.addClass( 'hover' );
		
			// Actualize widget
			FieldSelect.actualize( data );

			// Set item visible
			if( checkVisible === true ){
				FieldSelect.setOptionVisible( data, $item );
			}

		},

		// Set field value
		setValue : function( data, value ){

			// Set field value
			data.$field.val( value );
			
			// Set item visible
			var $item = data.$dropItems.filter( '[rel="'+ value +'"]' );
			FieldSelect.setOptionVisible( data, $item );

			// Actualize widget
			FieldSelect.actualize( data );
		
		},

		// Actualize values
		actualize : function( data ){

			// Value
			var 
				value = data.$field.val(),
				label = data.$options.filter( ':checked' ).html()
			;

			// Set value label
			data.$value.html( label );

			// Mark list item
			data.$dropItems.removeClass( 'active' );
			data.$dropItems.filter( '[rel="'+ value +'"]' ).addClass( 'active' );

			// Filled
			if( value !== '' ){
				data.$.addClass( 'filled' );
			}
			else {
				data.$.removeClass( 'filled' );
			}

			// Enabled and Disabled
			if( data.$field.prop( 'disabled' ) ){
				data.$.addClass( 'disabled' );
			}
			else {
				data.$.removeClass( 'disabled' );
			}
		
		},

		setOptionVisible : function( data, $item ){

			var 
				realTop = 0,
				visible = true,
				dropHeight = data.$dropList.innerHeight(),
				scrollTop = data.$dropList.scrollTop()
			;

			$item.prevAll().each(function(){
				this.$ = this.$ || $( this );
				if( this.$.is( ':visible' ) ){
					realTop += this.$.outerHeight();
				}
			});

			// Rola pra baixo
			if( realTop < scrollTop ){
				$item.get( 0 ).scrollIntoView( true );
			}
			// Rola pra cima
			else if( ( realTop + $item.outerHeight() ) > ( dropHeight + scrollTop ) ){
				$item.get( 0 ).scrollIntoView( false );
			}

		},

		// Check positions
		checkPositions : function(){

			FieldSelect.$items.each(function(){

				this.$ = this.$ || $( this );
				
				var data = this.$.data( 'FieldSelect' );
				if( data !== undefined ){
					FieldSelect.checkDropPosition( data );
				}
			
			});

		},

		checkDropPosition : function( data ){

			var 
			 	fieldTop = data.$.offset().top,
			 	fieldHeight = data.$.outerHeight( true ),
			 	dropHeight = data.$drop.outerHeight(),
			 	pageHeight = Common.getPageHeight(),
			 	scrollTop = Common.getPageTop(),
			 	spaceTop = data.$.offset().top,
			 	spaceBottom = ( pageHeight - fieldTop - fieldHeight ),
			 	top = null
			;

			// If top
			if( spaceBottom < dropHeight || spaceTop > spaceBottom ){

				data.$.addClass( 'drop-on-top' );

				top = ( data.$.offset().top - dropHeight - scrollTop );

			}
			else {

				data.$.removeClass( 'drop-on-top' );

				top = ( data.$.offset().top + fieldHeight - scrollTop );
			
			}

			data.$drop.css({
				width: data.$.outerWidth(),
				left: data.$.offset().left,
				top : top
			});

		},

		// Bind control
		bindControl : function( e, data ){

			// Lock mouse hover when keyborar control
			data.lockHover = true;
			
			var 
				$options = FieldSelect.getVisibleOptions( data ),
				$actual = data.$options.filter( ':selected' ),
				$actual_sel = data.$dropItems.filter( '.hover' ),
				$next = false,
				keynum = ( window.event ? e.keyCode : e.which ),
				opened = data.$.hasClass( 'opened' )
			;

			// If has not actual, get first
			if( !$actual.length ){
				$actual = $options.first();
			}

			// If opened, search by first pre-select, if exists
			if( opened && $actual_sel.length ){
				$actual = $options.filter( '[value="'+ $actual_sel.attr( 'rel' ) +'"]' );
			}
			
			// Numbers and letters ( Number, Ltter upper, Letter lower )
			if( ( keynum >= 48 && keynum <= 57 ) || ( keynum >= 65 && keynum <= 90 ) || ( keynum >= 97 && keynum <= 122 ) ){

				// Keep searching status
				data.searching = true;

				var 
					search = Common.removeAccents( data.$search.val() ).toLowerCase(),
					$math = false
				;

				$options.filter(function(){
				
					this.$ = this.$ || $( this );

					if( !$math ){
						var compare = Common.removeAccents( this.$.text() ).toLowerCase();
						if( compare.indexOf( search ) === 0 ){
							$math = this.$;
						}
					}
				
				});

				if( $math.length ){

					const value = $math.attr( 'value' );
					
					if( opened ){
						FieldSelect.preSelectValue( data, value, true );
					}
					else {
						FieldSelect.setValue( data, value );
					}
				
				}

				if( data.searchTime ) clearTimeout( data.searchTime );
				data.searchTime = setTimeout(function(){
					data.$search.val( '' );
					data.searching = false;
				}, 800 );
				
			}
			// Space bar
			else if( keynum == 32 || keynum == 13 ){

				// Keep space in search
				if( keynum == 32 && data.searching ){
					return true;
				}

				// Select pre-selected item
				if( opened ){

					if( $actual_sel.length ){
					
						var $to_check = $options.filter( '[value="'+ $actual_sel.attr( 'rel' ) +'"]' );
					
						FieldSelect.setValue( data, $to_check.attr( 'value' ) );
						FieldSelect.close( data );

					}
				
				}
				else {

					FieldSelect.open( data );
				
				}
			
			}
			// Others keys
			else {

				// Next value
				if( keynum === 40 ){
					var $last = $options.last();
					$next = $last.is( $actual ) ? false : $actual.next();
				}
				// Previous value
				else if( keynum === 38 ){
					var $first = $options.first();
					$next = $actual.is( $first ) ? false : $actual.prev();
				}

				// Set new value
				if( $next ){

					const value = $next.attr( 'value' );
					
					if( opened ){
						FieldSelect.preSelectValue( data, value, true );
					}
					else {
						FieldSelect.setValue( data, value );
					}
				
				}

			}

			// Close
			if( keynum === 27 ){
				FieldSelect.close( data );
			}

		},

		// Toggle items
		toggle : function( data ){
			if( data.$.hasClass( 'opened' ) ){
				FieldSelect.close( data );
			}
			else {
				FieldSelect.open( data );
			}
		}, 

		// Open select
		open : function( data  ){

			// Reposition dropdown
			FieldSelect.checkDropPosition( data );

			// Open dropdown
			data.$.addClass( 'opened' );
		
			// Active tabindexs
			data.$dropItems.attr( 'tabindex', '0' );
		
			// Focus on search
			data.$search.focus();
		
		},

		// Close select
		close : function( data ){
			data.$.removeClass( 'opened' );
			data.$dropItems.attr( 'tabindex', '-1' );
			data.$search.focus();
		},

	};

	$(function(){
		FieldSelect.init();
	});

})(jQuery);
